import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import jwtDecode from 'jwt-decode';
import { loginUser, sendOtpForLogin } from '../../actions/authAction';
import Auth, { Group } from '../../components/Auth';
import SEO from '../../components/SEO';
import { toast } from 'react-toastify';
import VerifyOtpModal from './verifyOtpModal';

export default function Login() {
  const [phone, setPhone] = useState('');
  const [pass, setPass] = useState('');
  const [isLoading, showLoader] = useState(false);
  const [error, setError] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [loginMethod, setLoginMethod] = useState('password');

  useEffect(() => {
    const token = localStorage.getItem('Token');
    if (token && isTokenValid(token)) {
      navigate('/complaints/');
    }
  }, []);

  const isTokenValid = (token) => {
    if (!token) {
      return false;
    }

    const decodedToken = jwtDecode(token);
    console.log('decoded token ', decodedToken);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp >= currentTime && decodedToken.user && decodedToken.user.user;
  };

  const changePhone = (e) => {
    setPhone(e.target.value);
  };

  const changePass = (e) => {
    setPass(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    showLoader(true);

    const userData = {
      phone: phone,
      password: pass,
    };

    try {
      if (loginMethod === 'otp') {
        await onSendOtp();
      } else {
        const response = await loginUser(userData);
        if (response?.status) navigate('/complaints');
        else toast.error(response?.error_message);
      }
    } catch (error) {
      setError('Incorrect username or password!');
      showLoader(false);
      setPhone('');
      setPass('');
    }
  };

  const onSendOtp = async () => {
    try {
      showLoader(true);
      let mobile_obj = { mobile: phone };
      let result = await sendOtpForLogin(mobile_obj);
      showLoader(false);
      if (result?.status) {
        setSessionId(result?.session_id);
        toast.success('OTP sent Successfully');
        setShowOtpModal(true);
      } else {
        setShowOtpModal(false);
        toast.error(result?.message);
      }
    } catch (error) {
      toast.error('Error in sendOtp');
    }
  };

  return (
    <>
      {showOtpModal && <VerifyOtpModal onClose={() => setShowOtpModal(false)} sessionId={sessionId} phone={phone} />}
      <Auth title="Login" subTitle="Hello! Login with your Phone">
        <SEO title="Login" />
        <form>
          <InputGroup fullWidth>
            <input type="text" placeholder="Phone Number" onChange={(e) => changePhone(e)} />
          </InputGroup>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <label>
              <input
                type="radio"
                name="loginMethod"
                value="otp"
                checked={loginMethod === 'otp'}
                onChange={() => setLoginMethod('otp')}
              />
              <span>Login via OTP</span>
            </label>
            <label>
              <input
                type="radio"
                name="loginMethod"
                value="password"
                checked={loginMethod === 'password'}
                onChange={() => setLoginMethod('password')}
              />
              <span>Login via Password</span>
            </label>
          </div>
          {loginMethod === 'password' && (
            <InputGroup fullWidth>
              <input type="password" placeholder="Password" onChange={(e) => changePass(e)} />
            </InputGroup>
          )}
          <Group>
            <Link to="/auth/request-password">Forgot Password?</Link>
          </Group>
          <Button status="Success" type="button" shape="SemiRound" fullWidth onClick={(e) => onSubmit(e)}>
            Login
          </Button>
        </form>
      </Auth>
    </>
  );
}
